<template>
  <div class="projectAdmin">
      <div id="container"></div>
  </div>
</template>
<script>
    import G6 from '@antv/g6';
    import insertCss from 'insert-css';
    export default {
        name: 'projectAdmin',

        data () {
            return {

            }
        },
        mounted(){
            let self = this;
            insertCss(`.g6-component-tooltip {background-color: rgba(0,0,0, 0.65);padding: 10px;box-shadow: rgb(174, 174, 174) 0px 0px 10px;width: fit-content;color: #fff;border-radius = 4px;}`);

// mocked data
            const mockData = {
                id: 'g1',
                name: '李泳章',
                label: '我发那是的看见你发送副经理打烂的说法的',
                rate: .5,
                status: 'B',
                img: self.__USER__.Avatar,
                children: [
                    {
                        id: 'g12',
                        name: 'Deal with LONG label LONG label LONG label LONG label',
                        count: 123456,
                        label: '338.00',
                        rate: 0.627,
                        status: 'R',
                        currency: 'Yuan',
                        variableName: 'V2',
                        variableValue: 0.179,
                        variableUp: true,
                        children: [
                            {
                                id: 'g121',
                                name: 'Name3',
                                collapsed: true,
                                count: 123456,
                                label: '138.00',
                                rate: 0.123,
                                status: 'B',
                                currency: 'Yuan',
                                variableName: 'V2',
                                variableValue: 0.27,
                                variableUp: true,
                                children: [
                                    {
                                        id: 'g1211',
                                        name: 'Name4',
                                        count: 123456,
                                        label: '138.00',
                                        rate: 1.0,
                                        status: 'B',
                                        currency: 'Yuan',
                                        variableName: 'V1',
                                        variableValue: 0.164,
                                        variableUp: false,
                                        children: [],
                                    },
                                ],
                            },
                            {
                                id: 'g122',
                                name: 'Name5',
                                collapsed: true,
                                count: 123456,
                                label: '100.00',
                                rate: 0.296,
                                status: 'G',
                                currency: 'Yuan',
                                variableName: 'V1',
                                variableValue: 0.259,
                                variableUp: true,
                                children: [
                                    {
                                        id: 'g1221',
                                        name: 'Name6',
                                        count: 123456,
                                        label: '40.00',
                                        rate: 0.4,
                                        status: 'G',
                                        currency: 'Yuan',
                                        variableName: 'V1',
                                        variableValue: 0.135,
                                        variableUp: true,
                                        children: [
                                            {
                                                id: 'g12211',
                                                name: 'Name6-1',
                                                count: 123456,
                                                label: '40.00',
                                                rate: 1.0,
                                                status: 'R',
                                                currency: 'Yuan',
                                                variableName: 'V1',
                                                variableValue: 0.181,
                                                variableUp: true,
                                                children: [],
                                            },
                                        ],
                                    },
                                    {
                                        id: 'g1222',
                                        name: 'Name7',
                                        count: 123456,
                                        label: '60.00',
                                        rate: 0.6,
                                        status: 'G',
                                        currency: 'Yuan',
                                        variableName: 'V1',
                                        variableValue: 0.239,
                                        variableUp: false,
                                        children: [],
                                    },
                                ],
                            },
                            {
                                id: 'g123',
                                name: 'Name8',
                                collapsed: true,
                                count: 123456,
                                label: '100.00',
                                rate: 0.296,
                                status: 'DI',
                                currency: 'Yuan',
                                variableName: 'V2',
                                variableValue: 0.131,
                                variableUp: false,
                                children: [
                                    {
                                        id: 'g1231',
                                        name: 'Name8-1',
                                        count: 123456,
                                        label: '100.00',
                                        rate: 1.0,
                                        status: 'DI',
                                        currency: 'Yuan',
                                        variableName: 'V2',
                                        variableValue: 0.131,
                                        variableUp: false,
                                        children: [],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'g13',
                        name: 'Name9',
                        count: 123456,
                        label: '100.90',
                        rate: 0.187,
                        status: 'B',
                        currency: 'Yuan',
                        variableName: 'V2',
                        variableValue: 0.221,
                        variableUp: true,
                        children: [
                            {
                                id: 'g131',
                                name: 'Name10',
                                count: 123456,
                                label: '33.90',
                                rate: 0.336,
                                status: 'R',
                                currency: 'Yuan',
                                variableName: 'V1',
                                variableValue: 0.12,
                                variableUp: true,
                                children: [],
                            },
                            {
                                id: 'g132',
                                name: 'Name11',
                                count: 123456,
                                label: '67.00',
                                rate: 0.664,
                                status: 'G',
                                currency: 'Yuan',
                                variableName: 'V1',
                                variableValue: 0.241,
                                variableUp: false,
                                children: [],
                            },
                        ],
                    },
                    {
                        id: 'g14',
                        name: 'Name12',
                        count: 123456,
                        label: '100.00',
                        rate: 0.186,
                        status: 'G',
                        currency: 'Yuan',
                        variableName: 'V2',
                        variableValue: 0.531,
                        variableUp: true,
                        children: [],
                    },
                ],
            };

            const colors = {
                B: '#5B8FF9',
                R: '#F46649',
                Y: '#EEBC20',
                G: '#5BD8A6',
                DI: '#A7A7A7',
            };

//  组件props
            const props = {
                data: mockData,
                config: {
                    padding: [20, 50],
                    defaultLevel: 3,
                    defaultZoom: 0.8,
                    modes: { default: ['zoom-canvas', 'drag-canvas'] },
                },
            };

            const container = document.getElementById('container');
            const width = container.scrollWidth;
            const height = container.scrollHeight || 500;

// 默认配置
            const defaultConfig = {
                width,
                height,
                modes: {
                    default: ['zoom-canvas', 'drag-canvas'],
                },
                fitView: true,
                animate: true,
                defaultNode: {
                    type: 'flow-rect',
                },
                defaultEdge: {
                    type: 'cubic-horizontal',
                    style: {
                        stroke: '#CED4D9',
                    },
                },
                layout: {
                    type: 'indented',
                    direction: 'LR',
                    dropCap: false,
                    indent: 300,
                    getHeight: () => {
                        return 60;
                    },
                },
            };

// 自定义节点、边
            const registerFn = () => {
                /**
                 * 自定义节点
                 */
                G6.registerNode(
                    'flow-rect',
                    {
                        shapeType: 'flow-rect',
                        draw(cfg, group) {
                            const {
                                name = '',
                                label,
                                collapsed,
                                status,
                                rate,
                                img,
                            } = cfg;
                            const grey = '#CED4D9';
                            // 逻辑不应该在这里判断
                            const rectConfig = {
                                width: 250,
                                height: 80,
                                lineWidth: 1,
                                fontSize: 16,
                                fill: '#fff',
                                radius: 4,
                                stroke: grey,
                                opacity: 1,
                            };

                            const nodeOrigin = {
                                x: -rectConfig.width / 2,
                                y: -rectConfig.height / 2,
                            };
                            const textConfig = {
                                textAlign: 'left',
                                textBaseline: 'bottom',
                            };

                            const rect = group.addShape('rect', {
                                attrs: {
                                    x: nodeOrigin.x,
                                    y: nodeOrigin.y,
                                    ...rectConfig,
                                },
                                name: 'item-container',
                            });

                            const rectBBox = rect.getBBox();

                            // label title
                            group.addShape('text', {
                                attrs: {
                                    ...textConfig,
                                    x: 50 + nodeOrigin.x,
                                    y: 35 + nodeOrigin.y,
                                    text: name.length > 28 ? name.substr(0, 28) + '...' : name,
                                    fontSize: 16,
                                    opacity: 0.85,
                                    fill: '#000',
                                    cursor: 'pointer',
                                },
                                name: 'name-shape',
                            });

                            group.addShape('image', {
                                attrs: {
                                    x: 12 + nodeOrigin.x,
                                    y: 10 + nodeOrigin.y,
                                    width: 30,
                                    height: 30,
                                    img: img
                                }
                            });

                            // price
                            const price = group.addShape('text', {
                                attrs: {
                                    ...textConfig,
                                    x: 12 + nodeOrigin.x,
                                    y: rectBBox.maxY - 12,
                                    text: label.length > 13 ? label.substr(0, 13) + '...' : label,
                                    fontSize: 16,
                                    fill: '#000',
                                    opacity: 0.85,
                                },
                            });

                            // bottom line background
                            const bottomBackRect = group.addShape('rect', {
                                attrs: {
                                    x: nodeOrigin.x,
                                    y: rectBBox.maxY - 4,
                                    width: rectConfig.width,
                                    height: 4,
                                    radius: [0, 0, rectConfig.radius, rectConfig.radius],
                                    fill: '#E0DFE3',
                                },
                            });

                            // bottom percent
                            const bottomRect = group.addShape('rect', {
                                attrs: {
                                    x: nodeOrigin.x,
                                    y: rectBBox.maxY - 4,
                                    width: rate * rectBBox.width,
                                    height: 4,
                                    radius: [0, 0, 0, rectConfig.radius],
                                    fill: colors[status],
                                },
                            });

                            // collapse rect
                            if (cfg.children && cfg.children.length) {
                                group.addShape('rect', {
                                    attrs: {
                                        x: rectConfig.width / 2 - 8,
                                        y: -8,
                                        width: 16,
                                        height: 16,
                                        stroke: 'rgba(0, 0, 0, 0.25)',
                                        cursor: 'pointer',
                                        fill: '#fff',
                                    },
                                    name: 'collapse-back',
                                    modelId: cfg.id,
                                });

                                // collpase text
                                group.addShape('text', {
                                    attrs: {
                                        x: rectConfig.width / 2,
                                        y: -1,
                                        textAlign: 'center',
                                        textBaseline: 'middle',
                                        text: collapsed ? '+' : '-',
                                        fontSize: 16,
                                        cursor: 'pointer',
                                        fill: 'rgba(0, 0, 0, 0.25)',
                                    },
                                    name: 'collapse-text',
                                    modelId: cfg.id,
                                });
                            }

                            this.drawLinkPoints(cfg, group);
                            return rect;
                        },
                        update(cfg, item) {
                            const group = item.getContainer();
                            this.updateLinkPoints(cfg, group);
                        },
                        setState(name, value, item) {
                            if (name === 'collapse') {
                                const group = item.getContainer();
                                const collapseText = group.find((e) => e.get('name') === 'collapse-text');
                                if (collapseText) {
                                    if (!value) {
                                        collapseText.attr({
                                            text: '-',
                                        });
                                    } else {
                                        collapseText.attr({
                                            text: '+',
                                        });
                                    }
                                }
                            }
                        },
                        getAnchorPoints() {
                            return [
                                [0, 0.5],
                                [1, 0.5],
                            ];
                        },
                    },
                    'rect',
                );

                G6.registerEdge(
                    'flow-cubic',
                    {
                        getControlPoints(cfg) {
                            let controlPoints = cfg.controlPoints; // 指定controlPoints
                            if (!controlPoints || !controlPoints.length) {
                                const { startPoint, endPoint, sourceNode, targetNode } = cfg;
                                const { x: startX, y: startY, coefficientX, coefficientY } = sourceNode
                                    ? sourceNode.getModel()
                                    : startPoint;
                                const { x: endX, y: endY } = targetNode ? targetNode.getModel() : endPoint;
                                let curveStart = (endX - startX) * coefficientX;
                                let curveEnd = (endY - startY) * coefficientY;
                                curveStart = curveStart > 40 ? 40 : curveStart;
                                curveEnd = curveEnd < -30 ? curveEnd : -30;
                                controlPoints = [
                                    { x: startPoint.x + curveStart, y: startPoint.y },
                                    { x: endPoint.x + curveEnd, y: endPoint.y },
                                ];
                            }
                            return controlPoints;
                        },
                        getPath(points) {
                            const path = [];
                            path.push(['M', points[0].x, points[0].y]);
                            path.push([
                                'C',
                                points[1].x,
                                points[1].y,
                                points[2].x,
                                points[2].y,
                                points[3].x,
                                points[3].y,
                            ]);
                            return path;
                        },
                    },
                    'single-line',
                );
            };

            registerFn();

            const { data } = props;
            let graph = null;

            const initGraph = (data) => {
                console.log(data)
                if (!data) {
                    return;
                }
                const { onInit, config } = props;
                const tooltip = new G6.Tooltip({
                    // offsetX and offsetY include the padding of the parent container
                    // offsetX 与 offsetY 需要加上父容器的 padding
                    offsetX: 20,
                    offsetY: 30,
                    // the types of items that allow the tooltip show up
                    // 允许出现 tooltip 的 item 类型
                    itemTypes: ['node'],
                    // custom the tooltip's content
                    // 自定义 tooltip 内容
                    getContent: (e) => {
                        const outDiv = document.createElement('div');
                        //outDiv.style.padding = '0px 0px 20px 0px';
                        const nodeName = e.item.getModel().name;
                        let formatedNodeName = '';
                        for (let i = 0; i < nodeName.length; i++) {
                            formatedNodeName = `${formatedNodeName}${nodeName[i]}`;
                            if (i !== 0 && i % 20 === 0) formatedNodeName = `${formatedNodeName}<br/>`;
                        }
                        outDiv.innerHTML = `${formatedNodeName}`;
                        return outDiv;
                    },
                    shouldBegin: (e) => {
                        if (e.target.get('name') === 'name-shape') return true;
                        return false;
                    },
                });
                graph = new G6.TreeGraph({
                    container: 'container',
                    ...defaultConfig,
                    ...config,
                    plugins: [tooltip],
                });
                if (typeof onInit === 'function') {
                    onInit(graph);
                }
                graph.data(data);
                graph.render();
                graph.zoom(config.defaultZoom || 1);

                const handleCollapse = (e) => {
                    const target = e.target;
                    const id = target.get('modelId');
                    const item = graph.findById(id);
                    const nodeModel = item.getModel();
                    nodeModel.collapsed = !nodeModel.collapsed;
                    graph.layout();
                    graph.setItemState(item, 'collapse', nodeModel.collapsed);
                };
                graph.on('collapse-text:click', (e) => {
                    handleCollapse(e);
                });
                graph.on('collapse-back:click', (e) => {
                    handleCollapse(e);
                });
                graph.on('item-container:click', (e) => {
                    console.log(e.item._cfg.model)
                    //handleCollapse(e);
                });
            };

            initGraph(data);

            if (typeof window !== 'undefined')
                window.onresize = () => {
                    if (!graph || graph.get('destroyed')) return;
                    if (!container || !container.scrollWidth || !container.scrollHeight) return;
                    graph.changeSize(container.scrollWidth, container.scrollHeight);
                };
        },
    }
</script>

<style>

</style>
<!--<template>-->
  <!--<div class="projectAdmin">-->
    <!--<div class="container">-->
      <!--<draggable v-model="infoData" style="display: flex;flex-wrap: wrap;" animation="300">-->
        <!--<div class="item" v-for="(item, index) in infoData" :key="index">-->
          <!--<div class="item-title flex" :style="{'background': bgColor[Math.ceil(Math.random()*6)]}">-->
            <!--<span class="title-text">{{ item.Name }}</span>-->
            <!--<a-popover placement="bottom">-->
              <!--<template slot="content">-->
                <!--<p style="padding-bottom: 5px;cursor: pointer" @click="_setData(item.Id,1)">编辑</p>-->
                <!--<p style="cursor: pointer" @click="_del(index)">删除</p>-->
              <!--</template>-->
              <!--<a-icon type="ellipsis" style="cursor: pointer" />-->
            <!--</a-popover>-->
          <!--</div>-->

          <!--<div class="item-content">-->
            <!--<draggable-->
                    <!--v-model="item.Children"-->
                    <!--force-fallback="true"-->
                    <!--group="people"-->
                    <!--animation="300"-->
                    <!--@start="_startDrag(index)"-->
                    <!--@add="_addDrag"-->
                    <!--@change="_changeDrag"-->
                    <!--@sort="_sortDrag"-->
            <!--&gt;-->
              <!--<div-->
                      <!--class="tab flex"-->
                      <!--:class="-->
                  <!--startIndex == index + '-' + tabIndex ? 'start-active' : ''-->
                <!--"-->
                      <!--v-for="(tab, tabIndex) in item.Children"-->
                      <!--:key="tabIndex"-->
                      <!--@click="_setData(tab.Id,2,item.Id)"-->
              <!--&gt;-->
                <!--<img class="tab-img" :src="tab.Avatar" alt="" />-->
                <!--<div style="flex: 1">-->
                  <!--<div class="tab-title">{{ tab.Name }}</div>-->
                  <!--<a-popover placement="bottomLeft">-->
                    <!--<template slot="content">-->
                      <!--<div>-->
                        <!--<div style="margin-bottom: 4px">当前进度</div>-->
                        <!--<a-input v-model="tab.Status" :id="'status-input-'+index+'-'+tabIndex" @input="_setMsg(index,tabIndex,'Speed')" addon-after="%" style="width: 180px;"/>-->
                      <!--</div>-->
                      <!--<div style="margin-top: 10px">状态</div>-->
                      <!--<div class="status-list">-->
                        <!--<div @click="_setMsg(index,tabIndex,'Status',1)">-->
                          <!--<span style="border-color:#3699FF"><i v-if="tab.ProjectStatus==1" style="background: #3699FF;"></i></span> <p>正常</p>-->
                        <!--</div>-->
                        <!--<div @click="_setMsg(index,tabIndex,'Status',2)">-->
                          <!--<span style="border-color:#F59336"><i v-if="tab.ProjectStatus==2" style="background: #F59336;"></i></span> <p>有风险</p>-->
                        <!--</div>-->
                        <!--<div @click="_setMsg(index,tabIndex,'Status',3)">-->
                          <!--<span style="border-color:#F74E60"><i v-if="tab.ProjectStatus==3" style="background: #F74E60;"></i></span> <p>已延期</p>-->
                        <!--</div>-->
                      <!--</div>-->
                    <!--</template>-->
                    <!--<span class="tab-item" :style="{'background':tab.ProjectStatus==3?'#FFE2E5':tab.ProjectStatus==2?'#FFF4DE':'#E1F0FF',color: tab.ProjectStatus==3?'#F74E60':tab.ProjectStatus==2?'#F59336':'#3699FF'}">{{tab.ProjectStatus==3?'已延期':tab.ProjectStatus==2?'有风险':'正常'}}{{tab.Status||0}}%</span>-->
                  <!--</a-popover>-->
                <!--</div>-->
              <!--</div>-->
            <!--</draggable>-->
            <!--<div>-->
              <!--<a-input placeholder="输入按回车提交" class="add-btn" @keyup.enter="_addRow($event,index)"></a-input>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="item">-->
          <!--<a-input placeholder="输入按回车提交" class="add-btn" v-model="pageAddText" @keyup.enter="_addRow($event)"></a-input>-->
        <!--</div>-->
      <!--</draggable>-->
    <!--</div>-->

    <!--<div class="page-title comment-title">-->
      <!--<span class="title-text">评论</span>-->
    <!--</div>-->

    <!--<div class="comment-list">-->
      <!--<ul>-->
        <!--<li v-for="(item, index) in commentList">-->
          <!--<img :src="item.Avatar" alt="" />-->
          <!--<div class="comment-msg">-->
            <!--<div class="user-msg">-->
              <!--<span class="user-name">{{ item.CreateUserName }}</span>-->
              <!--<span class="create-time">{{ item.TimeText }}</span>-->
            <!--</div>-->
            <!--<div class="comment-content">{{ item.Content }}</div>-->
          <!--</div>-->
        <!--</li>-->

        <!--<li>-->
          <!--<img style="margin-top: 0" :src="__USER__.Avatar" alt="" />-->
          <!--<div class="comment-msg">-->
            <!--<a-textarea-->
                    <!--class="textarea"-->
                    <!--v-model="textarea"-->
                    <!--placeholder="添加评论"-->
                    <!--@keyup.entee="_save"-->
            <!--&gt;</a-textarea>-->
          <!--</div>-->
        <!--</li>-->
      <!--</ul>-->
      <!--<div style="text-align: right;margin-top: 10px;margin-bottom: 20px;">-->
        <!--<a-button type="primary" @click="_save">评论</a-button>-->
      <!--</div>-->
    <!--</div>-->

    <!--<a-drawer title="详情" width="600" placement="right" :closable="false" @close="_cancelData()" :visible="detailVisible">-->
      <!--<div class="OKRDetails">-->
        <!--<div class="content">-->
          <!--<input-->
                  <!--class="int"-->
                  <!--type="text"-->
                  <!--placeholder="请添加标题"-->
                  <!--v-model="alertTitle"-->
          <!--/>-->
          <!--<ul class="flex total-div">-->
            <!--<li>-->
              <!--<div class="label-name">进度</div>-->
              <!--<div class="label-num"><a-input addon-after="%" v-model="schedule" :disabled="setType==1?true:false"></a-input></div>-->
            <!--</li>-->
            <!--<li>-->
              <!--<div class="label-name">权重</div>-->
              <!--<div class="label-num"><a-input addon-after="%" v-model="weight" :disabled="setType==1?true:false"></a-input></div>-->
            <!--</li>-->
            <!--<li style="width: 50px;">-->
              <!--<div class="label-name">总分</div>-->
              <!--<div class="label-num"><a-input v-model="total" :disabled="setType==1?true:false"></a-input></div>-->
            <!--</li>-->
          <!--</ul>-->
          <!--<div class="founder">-->
            <!--<div class="information">创建人</div>-->
            <!--<div class="theName">{{ userInfo.name }}</div>-->
          <!--</div>-->
          <!--<div class="starStop">-->
            <!--<div class="lintTime">起止时间</div>-->
            <!--<div class="lintDate">-->
              <!--<a-range-picker v-model="startDate" @change="_customDate" />-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="projectPersonnel">-->
            <!--<div class="responsiblePerson">-->
              <!--<div class="nickName">主要负责人(负责人只能添加一个)</div>-->
              <!--<div class="headPortrait">-->
                <!--<div class="itemPortrait" v-if="cutReadPerson">-->
                  <!--<img-->
                          <!--class="img"-->
                          <!--:src="cutReadPerson[0].icon || cutReadPerson[0].Avatar"-->
                          <!--alt=""-->
                  <!--/>-->
                  <!--<div>{{ cutReadPerson[0].title || cutReadPerson[0].UserName }}</div>-->
                <!--</div>-->
                <!--<div class="itemPortrait">-->
                  <!--<i-->
                          <!--class="iconfont"-->
                          <!--style="position: relative;top: -10px;font-size: 37px"-->
                  <!--&gt;&#xe676;</i-->
                  <!--&gt;-->
                  <!--<div></div>-->
                  <!--<person-->
                          <!--class="person"-->
                          <!--v-bind="personObj1"-->
                          <!--@getData="cutReadChangePerson"-->
                  <!--&gt;</person>-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="responsiblePerson" style="margin-top: 15px;">-->
              <!--<div class="nickName">参与人员</div>-->
              <!--<div class="headPortrait">-->
                <!--<div-->
                        <!--class="itemPortrait"-->
                        <!--v-if="participate"-->
                        <!--v-for="(item, index) in participate"-->
                        <!--:key="index"-->
                <!--&gt;-->
                  <!--<img class="img" :src="item.icon || item.Avatar" alt="" />-->
                  <!--<div>{{ item.title || item.UserName }}</div>-->
                  <!--<i-->
                          <!--class="iconfont icon-bohui del-icon"-->
                          <!--@click="_delPerson(index)"-->
                  <!--&gt;</i>-->
                <!--</div>-->
                <!--<div class="itemPortrait">-->
                  <!--<i-->
                          <!--class="iconfont"-->
                          <!--style="position: relative;top: -10px;font-size: 37px"-->
                  <!--&gt;&#xe676;</i-->
                  <!--&gt;-->
                  <!--<div></div>-->
                  <!--<person-->
                          <!--class="person"-->
                          <!--v-bind="personObj2"-->
                          <!--@getData="participatePerson"-->
                  <!--&gt;</person>-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="alert-footer">-->
        <!--<a-button style="margin-right: 25px" @click="_cancelData()"-->
        <!--&gt;取消</a-button-->
        <!--&gt;-->
        <!--<a-button type="primary" @click="_sub()">确定</a-button>-->
      <!--</div>-->
    <!--</a-drawer>-->
  <!--</div>-->
<!--</template>-->

<!--<script>-->
    <!--import person from '@/components/SelectPersonnel/SelectPersonnel'-->
    <!--import draggable from 'vuedraggable';-->
    <!--export default {-->
        <!--name: 'projectAdmin',-->
        <!--components: {-->
            <!--draggable,person-->
        <!--},-->
        <!--data () {-->
            <!--return {-->
                <!--infoData: [],-->
                <!--startIndex: '',-->
                <!--commentList: [],-->
                <!--bgColor: ['#DCE0E5','#DCE0E5','#F3E5EA','#E0EDFA','#DDF1F3'],-->
                <!--textarea: '',-->
                <!--userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')),-->
                <!--pageTitle: '',-->
                <!--detailVisible: false,-->

                <!--selectDate: '',-->
                <!--personObj1: {-->
                    <!--rank: 1, //职级 0为不选，1为选-->
                    <!--nums: 1, //选的数量-->
                    <!--department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人-->
                    <!--contacts: '', //常用联系人 不要常用联系人传空''-->
                    <!--selectArr: [] //要传入的数据-->
                <!--},-->
                <!--personObj2: {-->
                    <!--rank: 1, //职级 0为不选，1为选-->
                    <!--nums: 10000, //选的数量-->
                    <!--department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人-->
                    <!--contacts: '', //常用联系人 不要常用联系人传空''-->
                    <!--selectArr: [] //要传入的数据-->
                <!--},-->
                <!--setId: '',-->
                <!--alertTitle: '',-->
                <!--createPer: '',-->
                <!--startDate: [],-->
                <!--cutReadPerson: '',-->
                <!--participate: '',-->
                <!--schedule: '',-->
                <!--weight: '',-->
                <!--total: '',-->
                <!--setType: '',-->
                <!--pid: '',-->
                <!--pageAddText: '',-->

                <!--dragStartIndex: '',-->
                <!--changeDrag: ''-->

            <!--}-->
        <!--},-->
        <!--created() {-->
            <!--this.pageTitle = this.$route.query.title;-->
            <!--this._infoData();-->
        <!--},-->
        <!--methods: {-->
            <!--_setData(id,num,pid){-->
                <!--this.detailVisible = true;-->
                <!--this.setType = num;-->
                <!--this.setId = id;-->
                <!--this.pid = pid ? pid : '';-->
                <!--this.$axios.get(8000049, {OkrId:id}, res => {-->
                    <!--if (res.data.code == 1) {-->
                        <!--let data = res.data.data;-->
                        <!--this.alertTitle = data.Info.Name;-->
                        <!--this.selectDate = [data.Info.StartDate, data.Info.PlanEndDate];-->
                        <!--if(data.Info.StartDate){-->
                            <!--this.startDate = [this.__moment__(data.Info.StartDate),this.__moment__(data.Info.PlanEndDate)];-->
                        <!--}else{-->
                            <!--this.startDate = []-->
                        <!--}-->
                        <!--this.createPer = data.Info.CreateUserName;-->
                        <!--this.cutReadPerson = data.Responsible.length!=0?[data.Responsible]:'';-->
                        <!--this.participate = data.Participate;-->
                        <!--this.schedule = data.Info.Status;-->
                        <!--this.weight = data.Info.ProjectWeight;-->
                        <!--this.total = data.Info.ProjectScore;-->
                        <!--this.detailVisible = true;-->
                    <!--}-->
                <!--});-->
            <!--},-->
            <!--_customDate (date) {-->
                <!--this.selectDate = [-->
                    <!--this.__moment__(date[0]).format('YYYY-MM-DD'),-->
                    <!--this.__moment__(date[1]).format('YYYY-MM-DD')-->
                <!--];-->
                <!--console.log(this.selectDate)-->
            <!--},-->
            <!--_sub() {-->
                <!--let item = {-->
                    <!--Name: this.alertTitle,-->
                    <!--StartDate: this.selectDate[0],-->
                    <!--EndDate: this.selectDate[1],-->
                    <!--OperationType: 1,-->
                    <!--ProjectId: this.$route.query.id,-->
                    <!--Id: this.setId,-->
                    <!--Pid: this.pid,-->
                    <!--Directors: JSON.stringify(this.cutReadPerson),-->
                    <!--Participants: JSON.stringify(this.participate),-->
                    <!--OKRSpeed: this.schedule,-->
                    <!--OKRWeight: this.weight,-->
                    <!--OKRScore: this.total-->

                <!--};-->
                <!--this.$axios.post(8000043, item, res => {-->
                    <!--if (res.data.code == 1) {-->
                        <!--this._infoData();-->
                        <!--this._cancelData()-->
                    <!--}else{-->
                        <!--this.$message.error(res.data.message);-->
                    <!--}-->
                <!--});-->
            <!--},-->
            <!--_delPerson (i) {-->
                <!--this.participate.splice(i, 1)-->
            <!--},-->
            <!--participatePerson (value) {-->
                <!--this.participate = value;-->
            <!--},-->
            <!--cutReadChangePerson (value) {-->
                <!--this.cutReadPerson = value;-->
            <!--},-->
            <!--_cancelData () {-->
                <!--this.alertTitle = '';-->
                <!--this.createPer = this.userInfo;-->
                <!--this.startDate = [];-->
                <!--this.selectDate = '';-->
                <!--this.cutReadPerson = '';-->
                <!--this.participate = '';-->
                <!--this.setId = '';-->
                <!--this.setType = '';-->
                <!--this.pid = '';-->
                <!--this.detailVisible = false-->
            <!--},-->
            <!--_addRow(e,index){-->
                <!--if(e.target.value){-->
                    <!--let item = {-->
                        <!--Avatar: this.userInfo.Avatar,-->
                        <!--Name: e.target.value,-->
                        <!--ProjectStatus: 1,-->
                        <!--Status: 0,-->
                    <!--};-->
                    <!--this.$axios.post(8000047, {ProjectId: this.$route.query.id,Name: e.target.value,Pid: index||index==0?this.infoData[index].Id:''}, res => {-->
                        <!--if (res.data.code == 1) {-->
                            <!--res.data.data.Avatar = this.userInfo.Avatar;-->
                            <!--this.pageAddText = '';-->
                            <!--if(index||index==0){-->
                                <!--this.infoData[index].Children.push(res.data.data);-->
                            <!--}else{-->
                                <!--this.infoData.push(res.data.data)-->
                            <!--}-->
                        <!--}else{-->
                            <!--this.$message.error(res.data.message);-->
                        <!--}-->
                    <!--});-->
                <!--}-->

            <!--},-->
            <!--_setMsg(index,tabIndex,type,val){-->
                <!--let item = {-->
                    <!--OkrId: this.infoData[index].Children[tabIndex].Id,-->
                    <!--Type: type,-->
                    <!--Value: type=='Status' ? val : document.getElementById('status-input-'+index+'-'+tabIndex).value-->
                <!--};-->
                <!--this.$axios.post(8000046, item, res => {-->
                    <!--if (res.data.code == 1) {-->
                        <!--if(type=='Status'){-->
                            <!--this.infoData[index].Children[tabIndex].ProjectStatus = val;-->
                        <!--}-->
                        <!--//this.$message.success('修改成功');-->
                    <!--}else{-->
                        <!--this.$message.error(res.data.message);-->
                    <!--}-->
                <!--});-->
            <!--},-->
            <!--_infoData(){-->
                <!--this.$axios.get(8000040, {ProjectId: this.$route.query.id}, res => {-->
                    <!--if (res.data.code == 1) {-->
                        <!--if(res.data.data.length==0){-->
                            <!--this.infoData = [];-->
                        <!--}else{-->
                            <!--this.infoData = res.data.data;-->
                        <!--}-->
                        <!--console.log(this.infoData)-->
                    <!--}-->
                <!--});-->

                <!--this.$axios.get(8000044, {ProjectId: this.$route.query.id}, res => {-->
                    <!--if (res.data.code == 1) {-->
                        <!--this.commentList = res.data.data;-->
                    <!--}-->
                <!--});-->

            <!--},-->
            <!--_del(i) {-->
                <!--let self = this;-->
                <!--this.$confirm({-->
                    <!--title: '确认框',-->
                    <!--content: '你确定要删除吗？',-->
                    <!--okText: '确定',-->
                    <!--okType: 'danger',-->
                    <!--cancelText: '取消',-->
                    <!--onOk() {-->
                        <!--self.$axios.post(8000048, {Id: self.infoData[i].Id}, res => {-->
                            <!--if (res.data.code == 1) {-->
                                <!--self.infoData.splice(i, 1);-->
                            <!--}else{-->
                                <!--self.$message.error(res.data.message);-->
                            <!--}-->
                        <!--});-->
                    <!--},-->
                <!--});-->
            <!--},-->
            <!--_save () {-->
                <!--if (this.textarea) {-->
                    <!--this.$axios.post(8000045, {TargetType: 1,TargetId:this.$route.query.id,Content: this.textarea}, res => {-->
                        <!--if (res.data.code == 1) {-->
                            <!--this.commentList.push({-->
                                <!--Content: this.textarea,-->
                                <!--CreateUserName: this.__USER__.name,-->
                                <!--TimeText: '刚刚',-->
                                <!--Avatar: this.__USER__.Avatar-->
                            <!--});-->
                            <!--this.textarea = ''-->
                        <!--}-->
                    <!--});-->
                <!--}-->
            <!--},-->
            <!--_startDrag(i){-->
                <!--this.dragStartIndex = i;-->
            <!--},-->
            <!--_addDrag (e) {-->
                <!--this._draw(e.oldDraggableIndex,e.newDraggableIndex);-->
            <!--},-->
            <!--_changeDrag(e){-->
                <!--if(e.added){-->
                    <!--this.changeDrag = e.added.element;-->
                <!--}-->
                <!--if(e.moved){-->
                    <!--this.changeDrag = e.moved.element;-->
                <!--}-->
            <!--},-->
            <!--_draw(oldIdx,newIdx){-->
                <!--let toList = [];-->
                <!--let item = '';-->
                <!--let toId = '';-->

                <!--this.infoData.map(res=>{-->
                    <!--res.Children.map(tag=>{-->
                        <!--if(tag.Id == this.changeDrag.Id){-->
                            <!--toId = res.Id;-->
                            <!--item = res.Children;-->
                        <!--}-->
                    <!--})-->
                <!--});-->

                <!--item.map(res=>{-->
                    <!--toList.push(res.Id);-->
                <!--});-->
                <!--this.$axios.post(8000052, {-->
                    <!--formId: this.infoData[this.dragStartIndex].Id,-->
                    <!--toId: toId,-->
                    <!--toList: JSON.stringify(toList),-->
                    <!--targetId: this.changeDrag.Id,-->
                <!--}, res => {-->
                    <!--if (res.data.code == 1) {-->

                    <!--}-->
                <!--});-->
            <!--},-->
            <!--_sortDrag(){-->
                <!--let toList = [];-->
                <!--this.infoData[this.dragStartIndex].Children.map(res=>{-->
                    <!--toList.push(res.Id)-->
                <!--});-->
                <!--this.$axios.post(8000052, {-->
                    <!--formId: this.infoData[this.dragStartIndex].Id,-->
                    <!--toId: this.infoData[this.dragStartIndex].Id,-->
                    <!--toList: JSON.stringify(toList),-->
                    <!--targetId: this.changeDrag.Id,-->
                <!--}, res => {-->
                    <!--if (res.data.code == 1) {-->

                    <!--}-->
                <!--});-->
            <!--},-->
        <!--}-->
    <!--}-->
<!--</script>-->

<!--<style scoped lang="less">-->
  <!--.projectAdmin {-->
    <!--width: 100%;-->
    <!--height: 100%;-->
    <!--.page-title {-->
      <!--padding-left: 30px;-->
      <!--.title-text {-->
        <!--color: #3f4254;-->
        <!--font-size: 16px;-->
      <!--}-->
      <!--.cut-tab {-->
        <!--span {-->
          <!--display: inline-block;-->
          <!--vertical-align: middle;-->
          <!--width: 32px;-->
          <!--height: 30px;-->
          <!--margin-left: 10px;-->
          <!--background: #fff;-->
          <!--border-radius: 8px;-->
        <!--}-->
      <!--}-->
    <!--}-->
    <!--.container {-->
      <!--display: flex;-->
      <!--align-items: end;-->
      <!--padding: 20px;-->
      <!--background: #fff;-->
      <!--margin-top: 15px;-->
      <!--border-radius: 5px;-->
      <!--.item {-->
        <!--width: 250px;-->
        <!--margin-right: 20px;-->
        <!--margin-bottom: 15px;-->
        <!--.item-title {-->
          <!--padding: 12px 15px;-->
          <!--border-top-left-radius: 3px;-->
          <!--border-top-right-radius: 3px;-->
          <!--background: #f3e5ea;-->
          <!--.title-text {-->
            <!--width: 200px;-->
            <!--color: #181c32;-->
            <!--font-size: 15px;-->
          <!--}-->
        <!--}-->
        <!--.item-content {-->
          <!--padding: 15px;-->
          <!--background: #f3f6f9;-->
          <!--border-bottom-left-radius: 3px;-->
          <!--border-bottom-right-radius: 3px;-->
          <!--.tab {-->
            <!--margin-bottom: 15px;-->
            <!--background: #fff;-->
            <!--padding: 15px;-->
            <!--border-radius: 5px;-->
            <!--box-shadow: 1px 2px 4px 0 #eee;-->
            <!--&:last-child {-->
              <!--margin-bottom: 0;-->
            <!--}-->

            <!-- -moz-user-select: none; /*火狐*/-->
            <!-- -webkit-user-select: none; /*webkit浏览器*/-->
            <!-- -ms-user-select: none; /*IE10*/-->
            <!-- -khtml-user-select: none; /*早期浏览器*/-->
            <!--user-select: none;-->
            <!--.tab-img {-->
              <!--width: 52px;-->
              <!--height: 52px;-->
              <!--margin-right: 10px;-->
              <!--border-radius: 4px;-->
            <!--}-->
            <!--.tab-title {-->
              <!--margin-bottom: 4px;-->
              <!--color: #7e8299;-->
              <!--font-size: 15px;-->
            <!--}-->
            <!--.tab-item {-->
              <!--padding: 4px 8px;-->
              <!--color: #3699ff;-->
              <!--background: #e1f0ff;-->
              <!--font-size: 12px;-->
              <!--border-radius: 2px;-->
            <!--}-->
            <!--&.start-active {-->
              <!--background: #eee;-->
            <!--}-->
          <!--}-->
        <!--}-->
      <!--}-->
    <!--}-->
    <!--.comment-title {-->
      <!--padding-left: 0;-->
      <!--padding-bottom: 8px;-->
      <!--margin-top: 50px;-->
      <!--border-bottom: 1px solid #e6e8eb;-->
    <!--}-->
    <!--.comment-list {-->
      <!--ul {-->
        <!--margin-top: 30px;-->
        <!--li {-->
          <!--display: flex;-->
          <!--margin-top: 15px;-->
          <!--img {-->
            <!--width: 35px;-->
            <!--height: 35px;-->
            <!--border-radius: 50%;-->
            <!--margin-right: 10px;-->
            <!--margin-top: -2px;;-->
          <!--}-->
          <!--.comment-msg {-->
            <!--flex: 1;-->
            <!--.user-msg {-->
              <!--.user-name {-->
                <!--margin-right: 8px;-->
                <!--color: #3f4254;-->
                <!--font-size: 15px;-->
              <!--}-->
              <!--.create-time {-->
                <!--color: #b1b3ba;-->
                <!--font-size: 12px;-->
              <!--}-->
            <!--}-->
            <!--.comment-content {-->
              <!--margin-top: 5px;-->
              <!--color: #3f4254;-->
              <!--font-size: 14px;-->
            <!--}-->
            <!--.textarea {-->
              <!--width: 100%;-->
              <!--height: 70px;-->
              <!--resize: none;-->
            <!--}-->
          <!--}-->
        <!--}-->
      <!--}-->
    <!--}-->
    <!--.add-btn{-->
      <!--text-align: center;-->
      <!--margin-top: 15px;-->
      <!--padding: 5px 0;-->
      <!--border-radius: 6px;-->
      <!--height: 35px;-->
    <!--}-->
  <!--}-->
  <!--.status-list{-->
    <!--&gt;div{-->
      <!--margin-top: 2px;-->
      <!--cursor: pointer;-->
    <!--}-->
    <!--span{-->
      <!--position: relative;-->
      <!--display: inline-block;-->
      <!--width: 14px;-->
      <!--height: 14px;-->
      <!--margin-right: 5px;-->
      <!--border: 1px solid #ddd;-->
      <!--border-radius: 50%;-->
      <!--vertical-align: middle;-->
    <!--}-->
    <!--p{-->
      <!--display: inline-block;-->
      <!--vertical-align: middle;-->
    <!--}-->
    <!--i{-->
      <!--position: absolute;-->
      <!--top: 50%;-->
      <!--left: 50%;-->
      <!--width: 5px;-->
      <!--height: 5px;-->
      <!--transform: translate(-50%,-50%);-->
      <!--border-radius: 50%;-->
      <!--background: #ddd;-->
    <!--}-->
  <!--}-->
  <!--.OKRDetails {-->
    <!--opacity: 1;-->
    <!--background-color: #fff;-->
    <!--.details {-->
      <!--padding: 18px 23px;-->
      <!--border-bottom: 1px solid #ccc;-->
      <!--.title {-->
        <!--font-size: 16px;-->
        <!--font-weight: 400;-->
        <!--line-height: 22px;-->
        <!--color: #3f4254;-->
        <!--opacity: 1;-->
      <!--}-->
    <!--}-->
    <!--.content {-->
      <!--input:focus {-->
        <!--outline: none;-->
      <!--}-->
      <!--.int {-->
        <!--border: none;-->
        <!--width: 100%;-->
        <!--padding: 15px;-->
        <!--background: #ffffff;-->
        <!--border: 1px solid #3370ff;-->
        <!--opacity: 1;-->
        <!--border-radius: 5px;-->
      <!--}-->
      <!--.progressThan {-->
        <!--margin-top: 15px;-->
        <!--display: flex;-->
        <!--justify-content: start;-->
        <!--.progress {-->
          <!--padding-left: 25px;-->
          <!--padding-right: 35px;-->
        <!--}-->
        <!--.totalScore {-->
          <!--padding-left: 35px;-->
        <!--}-->
      <!--}-->
      <!--.founder {-->
        <!--margin-top: 26px;-->
        <!--.information {-->
          <!--font-size: 14px;-->
          <!--font-weight: 400;-->
          <!--line-height: 17px;-->
          <!--color: #b5b6c7;-->
          <!--opacity: 1;-->
        <!--}-->
        <!--.theName {-->
          <!--margin-top: 9px;-->
          <!--font-size: 16px;-->
          <!--font-weight: 400;-->
          <!--line-height: 20px;-->
          <!--color: #3f4254;-->
          <!--opacity: 1;-->
        <!--}-->
      <!--}-->
      <!--.starStop {-->
        <!--margin-top: 16px;-->
        <!--.lintTime {-->
          <!--font-size: 14px;-->
          <!--font-weight: 400;-->
          <!--line-height: 17px;-->
          <!--color: #b5b6c7;-->
          <!--opacity: 1;-->
        <!--}-->
        <!--.lintDate {-->
          <!--margin-top: 9px;-->
          <!--font-size: 16px;-->
          <!--font-weight: 400;-->
          <!--line-height: 20px;-->
          <!--color: #3f4254;-->
          <!--opacity: 1;-->
        <!--}-->
      <!--}-->
      <!--.projectPersonnel {-->
        <!--margin-top: 27px;-->
        <!--.responsiblePerson {-->
          <!--display: flex;-->
          <!--flex-direction: column;-->
          <!--.nickName {-->
            <!--font-size: 14px;-->
            <!--font-weight: 400;-->
            <!--line-height: 17px;-->
            <!--color: #b5b6c7;-->
            <!--opacity: 1;-->
          <!--}-->
          <!--.headPortrait {-->
            <!--position: relative;-->
            <!--margin-top: 9px;-->
            <!--display: flex;-->
            <!--align-items: center;-->
            <!--.itemPortrait {-->
              <!--position: relative;-->
              <!--margin-right: 15px;-->
              <!--.del-icon {-->
                <!--position: absolute;-->
                <!--top: -8px;-->
                <!--right: 0;-->
                <!--width: 10px;-->
                <!--height: 10px;-->
                <!--color: #ff0000;-->
              <!--}-->
              <!--.person {-->
                <!--position: absolute;-->
                <!--top: 0;-->
                <!--left: 0;-->
                <!--width: 100%;-->
                <!--opacity: 0;-->
                <!--z-index: 100;-->
              <!--}-->
              <!--.img {-->
                <!--width: 36px;-->
                <!--height: 36px;-->
                <!--background: rgba(0, 0, 0, 0);-->
                <!--border-radius: 50%;-->
                <!--opacity: 1;-->
                <!--border: 1px solid #ccc;-->
                <!--margin-bottom: 6px;-->
              <!--}-->
              <!--div {-->
                <!--font-size: 12px;-->
                <!--font-weight: 400;-->
                <!--line-height: 17px;-->
                <!--color: #3f4254;-->
                <!--opacity: 1;-->
                <!--text-align: center;-->
              <!--}-->
            <!--}-->
            <!--.icon {-->
              <!--position: relative;-->
              <!--.iconfont {-->
                <!--font-size: 36px;-->
                <!--color: #c1c5cc;-->
              <!--}-->
            <!--}-->
          <!--}-->
        <!--}-->
        <!--.participants {-->
          <!--margin-top: 13px;-->
          <!--.secondary {-->
            <!--font-size: 14px;-->
            <!--font-weight: 400;-->
            <!--line-height: 17px;-->
            <!--color: #b5b6c7;-->
            <!--opacity: 1;-->
          <!--}-->
          <!--.headPortrait {-->
            <!--position: relative;-->
            <!--margin-top: 9px;-->
            <!--.itemPortrait {-->
              <!--.img {-->
                <!--width: 36px;-->
                <!--height: 36px;-->
                <!--background: rgba(0, 0, 0, 0);-->
                <!--border-radius: 50%;-->
                <!--opacity: 1;-->
                <!--border: 1px solid #ccc;-->
                <!--margin-bottom: 6px;-->
              <!--}-->
              <!--div {-->
                <!--font-size: 12px;-->
                <!--font-weight: 400;-->
                <!--line-height: 17px;-->
                <!--color: #3f4254;-->
                <!--opacity: 1;-->
              <!--}-->
            <!--}-->
            <!--.icon {-->
              <!--position: absolute;-->
              <!--left: 35px;-->
              <!--top: -8px;-->
              <!--.iconfont {-->
                <!--padding-left: 18px;-->
                <!--font-size: 36px;-->
                <!--color: #c1c5cc;-->
              <!--}-->
            <!--}-->
          <!--}-->
        <!--}-->
      <!--}-->
    <!--}-->
    <!--.btn {-->
      <!--padding: 0 15px;-->
      <!--padding-top: 80px;-->
      <!--float: right;-->
      <!--.cancel {-->
        <!--padding: 6px 18px;-->
        <!--margin-right: 15px;-->
        <!--outline: none;-->
        <!--border: none;-->
      <!--}-->
      <!--.cancel:hover {-->
        <!--background-color: #2994ff;-->
        <!--color: #fff;-->
      <!--}-->
      <!--.confirm {-->
        <!--padding: 6px 18px;-->
        <!--outline: none;-->
        <!--border: none;-->
      <!--}-->
      <!--.confirm:hover {-->
        <!--background-color: #2994ff;-->
        <!--color: #fff;-->
      <!--}-->
    <!--}-->
    <!--.comments {-->
      <!--float: left;-->
      <!--padding: 0 15px;-->
      <!--.title {-->
        <!--font-size: 18px;-->
        <!--font-weight: 400;-->
        <!--line-height: 25px;-->
        <!--color: #3f4254;-->
        <!--opacity: 1;-->
      <!--}-->
    <!--}-->
  <!--}-->
  <!--.alert-footer {-->
    <!--position: absolute;-->
    <!--left: 0;-->
    <!--bottom: 0;-->
    <!--width: 100%;-->
    <!--border-top: 1px solid #e9e9e9;-->
    <!--padding: 10px 16px;-->
    <!--background: #fff;-->
    <!--button {-->
      <!--width: 120px;-->
    <!--}-->
  <!--}-->
  <!--.total-div{-->
    <!--margin: 15px 0;-->
    <!--width: 280px;-->
    <!--li{-->
      <!--flex: 1;-->
      <!--text-align: center;-->
      <!--margin-right: 10px;-->
      <!--.label-name{-->
        <!--margin-bottom: 5px;-->
        <!--font-size: 13px;-->
        <!--color: #8F959E;-->
      <!--}-->
      <!--.label-num{-->
        <!--color: #3F4254;-->
        <!--font-size: 15px;-->
      <!--}-->
    <!--}-->
  <!--}-->
<!--</style>-->
